<template>
	<div class="sign-result-wrap">
		<div class="sign-img">
			<img src="../../../../assets/mobileimages/ok.png" />
			<p>投票成功！</p>
		</div>
		<!--<div class="sign-btn">-->
		<!--<Button @click="close">关闭</Button>-->
		<!--</div>-->
	</div>
</template>

<script>
export default {
	name: "VoteSuccess",
	data() {
		return {}
	},
	methods: {
		close() {
			const isLppzApp = false
			const ua = navigator.userAgent.toLowerCase()
			const uaApp = ua ? ua.match(/BeStore/i) : "" // match方法返回的是对象
			const uaAndroid = /android/i.test(ua) // test返回的是true/false
			const uaIos = /iphone|ipad|ipod/i.test(ua)
			// if (uaApp.toString() === 'bestore') { // 必须将match返回的对象转成字符串
			//   isLppzApp = true;
			// } else {
			//   isLppzApp = false;
			// }
			if (window.WeixinJSBridge) {
				window.WeixinJSBridge.call("closeWindow") // 微信
			} else if (window.AlipayJSBridge) {
				window.AlipayJSBridge.call("closeWebview") // 支付宝
			} else if (isLppzApp && uaAndroid) {
				window.obj.closePageLppzRequest("") // 安卓app
			} else if (isLppzApp && uaIos) {
				window.webkit.messageHandlers.closePageLppzRequest.postMessage("") // ios app
			} else {
				window.close()
			}
		}
	}
}
</script>

<style lang="less">
body {
	min-width: 0;
}
.sign-result-wrap {
	position: relative;
	z-index: 2;
	height: 100%;
	background-color: #fefdf8;
	text-align: center;
	.sign-img {
		padding-top: 170px;
		img {
			width: 230px;
		}
		p {
			font-size: 16px;
			line-height: 50px;
			color: #333;
		}
	}
	.sign-btn {
		margin-top: 130px;
		text-align: center;
		.ivu-btn {
			height: 35px;
			font-size: 16px;
			line-height: 22px;
			color: #fba11c;
			border-radius: 5px;
			border: 1px solid #fba11c;
			background: none;
		}
	}
}
</style>
